<template>
  <div>
    <header-component/>
    <popup-component />
    <router-view></router-view>

    <footer-component/>
    <cookies-component/>
  </div>
</template>

<script>
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderComponent from "./_partials/header";
import FooterComponent from "./_partials/footer";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  }
};
</script>

<style scoped>
@import url("../assets/css/style.css");
@import url("../assets/css/responsive.css");
@import url("../assets/css/custom.css");
</style>
