<template>
<div>
	<footer class="footer">
        <div class="container">
            <div class="row" v-for="(config, index) in configs" :key="index">
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <div class="widget clearfix">
                        <div class="widget-title">
                            <img src="@/assets/images/logo.png" alt="" style="filter: contrast(10);" width="100px"/>
                        </div>
						<div class="social-box" style="float:left">
							<ul>
								<li v-if="config.facebook"><a :href="config.facebook" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
                        		<li v-if="config.instagram"><a :href="config.instagram"  target="_blank"><i class="fa fa-instagram"></i></a></li>
                        		<li v-if="config.twitter"><a :href="config.twitter" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        		<li v-if="config.youtube"><a :href="config.youtube" target="_blank"><i class="fa fa-youtube"></i></a></li>
							</ul>
						</div>
                    </div><!-- end clearfix -->
                </div><!-- end col -->

				<div class="col-md-4 col-sm-4 col-xs-12">
                    <div class="widget clearfix">
                        <div class="widget-title">
                            <h3>Links Úteis</h3>
                        </div>

                        <ul class="footer-links hov">
							<li><router-link :to="{name: 'posgraduacao'}">Pós Graduação <span class="icon icon-arrow-right2"></span></router-link></li>
							<li><router-link :to="{name: 'politicadeprivacidade'}">Política de Privacidade <span class="icon icon-arrow-right2"></span></router-link></li>
							<li><router-link :to="{name: 'centralderelacionamento'}">Central de Relacionamento <span class="icon icon-arrow-right2"></span></router-link></li>
							<li><router-link :to="{name: 'contato'}">Contato <span class="icon icon-arrow-right2"></span></router-link></li>
                        </ul><!-- end links -->
                    </div><!-- end clearfix -->
                </div><!-- end col -->
				
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <div class="footer-distributed widget clearfix">
                        <div class="widget-title">
                            <h3>Contatos</h3>
							<p class="lead">{{ config.endereco }}</p>
							<p class="lead">{{ config.cnpj }}</p>
							<p class="lead">{{ config.celular }}</p>
							<p class="lead">{{ config.email }}</p>
						</div>                   
                    </div><!-- end clearfix -->
                </div><!-- end col -->
            </div><!-- end row -->
        </div><!-- end container -->
    </footer><!-- end footer -->

    <div class="copyrights">
        <div class="container">
            <div class="footer-distributed">
                <div class="footer-left">                   
                    <p class="footer-company-name">Todos os direitos reservados. &copy; 2021</p>
                </div>

                
            </div>
        </div><!-- end container -->
    </div><!-- end copyrights -->
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getConfigs()
            .catch(response => this.$vToastify.error('Falha ao carregar configurações', 'Erro'))
    },

    computed: {
        ...mapState({
            configs: state => state.configs.items
        })
    },

    methods: {
        ...mapActions([
            'getConfigs',
        ])
    },
}
</script>