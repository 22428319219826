<template>
    <div>
        <div class="banner-area banner-bg-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="banner">
                            <h2>Pós-Graduação</h2>
                            <ul class="page-title-link">
                                <li><a href="#">Início</a></li>
                                <li><a href="#">Pós-Graduação</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End header -->
        <div class="blog-box section wb">
            <div class="container">
                <div class="row">
                    <!-- Blog Entries Column -->
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6 portfolio-item" v-for="(course, index) in courses.data" :key="index">
                                <div class="card h-100 pricingTable">
                                    <div class="course-img">
                                        <img v-if="course.image"
                                            class="img-fluid" 
                                            :src="'https://uniminasead.com.br/uploads/'+course.image" 
                                            :alt="course.titulo">
                                        <img v-else 
                                            class="img-fluid" 
                                            src="https://uniminasead.com.br/uploads/cursos/default.jpg" 
                                            :alt="course.titulo">
                                    </div>
                                    <div class="card-body">
                                        <h4>{{ course.titulo }}</h4>
                                        <p class="card-text">{{ course.categoria }}</p>
                                        <router-link class="pricingTable-signup" :to="{name: 'curso', params: {courseUrl: course.url}}">
                                                <span><i class="fa fa-sign-in" aria-hidden="true"></i> Ver Curso</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pagination_bar">
                            <pagination :data="courses" :limit="10" align="center" show-disabled="true" @pagination-change-page="getResults">
                            </pagination>
                        </div>                   
                    </div>
                    <!-- Sidebar Widgets Column -->
                    <div class="col-md-3 blog-right-side">
                        <!-- Categories Widget -->
                        <div class="card my-4">
                            <h5 class="card-header">Categorias</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="list-unstyled mb-0" id="courses-filters">
                                            <li @click="filterByCategory('')" :class="[categoryInFilter('')]">
                                                TODOS OS CURSOS
                                            </li>
                                            <li v-for="(category, index) in categories" :key="index" @click="filterByCategory(category)" :class="[categoryInFilter(category)]">
                                                {{ category.titulo }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getCategories()
            .catch(response => this.$vToastify.error('Falha ao carregar categorias', 'Erro'))
        this.loadCourses()
    },

    computed: {
        ...mapState({
            categories: state => state.categories.items,
            courses: state => state.courses.items
        })
    },

    data() {
        return {
            filters: {
                category: ''
            },
        }
    },

    methods: {
        ...mapActions([
            'getCategories',
            'getCourses',
        ]),

        loadCourses () {
            const params = {
                page: this.$route.query.pag
            }

            if (this.filters.category) {
                params.category = this.filters.category
            }

            this.getCourses(params)
                .catch(response => this.$vToastify.error('Falha ao carregar cursos', 'Erro'))
        },
        
        filterByCategory (category) {
            this.filters.category = category.id

            this.loadCourses ()
        },

        categoryInFilter (category) {
            return category.id === this.filters.category ? 'filter-active' : ''
        },

        getResults(page = 1) {
            const params = {
                page: page
            }
			this.getCourses(params)
                .catch(response => this.$vToastify.error('Falha ao carregar cursos', 'Erro'))
		},

    }
}
</script>