require('./bootstrap')
require('./plugins')
import Vue from 'vue'
import DefaultTemplate from './layouts/DefaultTemplate'
import router from './routes'
import store from './store'

Vue.config.productionTip = false

/**
 * Global Components
 */
 Vue.component('popup-component', () => import('./components/PopUp'))
Vue.component('preloader-component', () => import('./components/Preloader'))
Vue.component('cookies-component', () => import('./components/Cookies'))
Vue.component('pagination', require('laravel-vue-pagination'))

new Vue({
  render: h => h(DefaultTemplate),
  router,
  store
}).$mount('#app')
