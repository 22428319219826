<template>
    <div>
        <!-- Start slides -->
        <div id="slides" class="cover-slides">
            <ul class="slides-container">
                <li class="text-left">
                    <img src="@/assets/images/slider-01.jpg" alt="">
                </li>
            </ul>
        </div>
        <!-- End slides -->
        
        <div id="about" class="section wb">
            <div class="container">
            
                <hr class="hr1"> 

                <div class="row">
                    <div class="col-md-6">
                        <div class="post-media wow fadeIn">
                            <img src="@/assets/uploads/about_02.jpg" alt="" class="img-responsive img-rounded">
                        </div><!-- end media -->
                    </div><!-- end col -->
                    
                    <div class="col-md-6" v-for="(pg, index) in page" :key="index">
                        <div class="message-box">
                            <h4>Um pouco da nossa história</h4>
                            <h2>{{ pg.title }}</h2>
                            <span v-html="pg.content.substr(0, 500)+'...'"></span>
                            <router-link class="btn btn-light btn-radius btn-brd grd1" :to="{name: 'institucional'}"><span>Leia Mais</span></router-link>
                        </div><!-- end messagebox -->
                    </div><!-- end col -->
                </div><!-- end row -->

                <hr class="hr1">
            </div><!-- end container -->
        </div><!-- end section -->
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    mounted() {
        this.getPage('quemsomos')
            .catch(response => this.$vToastify.error('Falha ao carregar institucional', 'Erro'))
    },

    computed: {
        ...mapState({
            page: state => state.page.items
        })
    },

    methods: {
        ...mapActions([
            'getPage',
        ])
    }
}
</script>