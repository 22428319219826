<template>
<div>
    <div class="top-bar">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-6">
					<div class="left-top">
						<div class="email-box">
							
						</div>
						<div class="phone-box">
							
						</div>
					</div>
				</div>
				<div class="col-md-6 col-sm-6">
					<div class="right-top">
						<div class="social-box">
							<ul>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- Start header -->
	<header class="header header_style_01">
		<nav class="megamenu navbar navbar-expand-lg navbar-default">
			<div class="container">
				<a class="navbar-brand" href="/">
					<img src="@/assets/images/logo.png" alt="Unibh EAD" width="200px"/>
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-rs-food" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation">
				  <span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbars-rs-food">
					<ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'home'}" exact>Início</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'institucional'}">Institucional</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'cursos'}">Pós Graduação</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'contato'}">Contato</router-link>
                        </li>
                        <li class="nav-item active">
                            <router-link class="nav-link matricula" :to="{name: 'matricula'}">Matricule-se</router-link>
                        </li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<!-- End header -->
</div>    
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getConfigs()
            .catch(response => this.$vToastify.error('Falha ao carregar configurações', 'Erro'))
    },

    computed: {
        ...mapState({
            configs: state => state.configs.items
        })
    },

    methods: {
        ...mapActions([
            'getConfigs',
        ])
    },
}
</script>