<template>
    <div>
        <div class="banner-area banner-bg-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="banner">
                            <h2>Pós-Graduação</h2>
                            <ul class="page-title-link">
                                <li><a href="#">Início</a></li>
                                <li><a href="#">Pós-Graduação</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End header -->
        <div id="about" class="section wb">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="message-box" v-for="(pg, index) in page" :key="index">
                            <h4>Informaçòes sobre a</h4>
                            <h2>{{ pg.title }}</h2>
                            <span v-html="pg.content"></span>
                        </div><!-- end messagebox -->
                    </div><!-- end col -->
                </div><!-- end row -->

                <hr class="hr1"> 
            </div><!-- end container -->
        </div><!-- end section -->
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getPage('posgraduacao')
            .catch(response => this.$vToastify.error('Falha ao carregar informações', 'Erro'))
    },

    computed: {
        ...mapState({
            page: state => state.page.items
        })
    },

    methods: {
        ...mapActions([
            'getPage',
        ])
    }
}
</script>